<template>
	<div class="wf">

		<!-- 最新激活记录 -->
		<div class="wf">
			<el-table :data="commentList" :stripe="true" border class="mgtop10">
				<el-table-column label="序号" type="index" width="55"></el-table-column>
				<el-table-column label="用户信息" prop="nickname" width="160px"></el-table-column>
				<el-table-column prop="reply" label="评论内容"></el-table-column>
				<el-table-column label="发布时间" prop="subTime" align="center" width="160px"></el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="commentList.length>0" @size-change="pageSizeChange"
						@current-change="pageChange" :current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="searchForm.total" background class="fr"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				commentForm: {},
				commentList: []
			}
		},
		props: ["newsForm"],
		methods: {
			doSearch() {
				this.searchForm.page = 1;
				this.getCommentList();
			},
			getCommentList() {
				this.searchForm.material = this.newsForm.serial;
				this.$http.request(this, this.$apis.material.commentList, this.searchForm, false, (res) => {
					if (res.code == 0) {
						this.searchForm.total = res.count;
						this.commentList = res.data;
					}
				});
			},
			showChange() {
				this.dialogVisible = !this.dialogVisible;
			}
		},
		created() {
			this.getCommentList();
		}
	}
</script>

<style>
	#commentForm .el-dialog__body {
		padding: 0 !important;
	}
</style>
