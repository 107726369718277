<template>
	<div class="wf">

		<el-dialog :close-on-click-modal="false" title="帖子/问答信息" :visible.sync="dialogVisible" width="1000px">

			<div class="wf">
				<el-form ref="newsForm" :model="newsForm" label-width="120px" :rules="rules">
					<el-row class="wf">
						<el-col :span="24">
							<el-form-item label="标题" prop="title">
								<el-input v-model="newsForm.title" placeholder="请填写文章标题" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="素材来源" prop="origin">
								<el-input v-model="newsForm.origin" placeholder="请填写素材来源" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="素材作者" prop="author">
								<el-input v-model="newsForm.memberName" placeholder="请填写素材作者手机号/编号" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="发布时间" prop="subTime">
								<el-date-picker v-model="newsForm.subTime" type="datetime" placeholder="选择发布时间"
									style="width:100%;" value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="分类标签" prop="tags">
								<el-select v-model="newsForm.tags" placeholder="请选择分类标签" style="width:100%;">
									<el-option value="帖子"></el-option>
									<el-option value="问答"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<div class="wf mgtop20">
							<div class="wf bold font14 h40 lineh40" style="border-bottom: 1px dashed #DEDEDE;">
								<span><i class="fa fa-bars mgr5" style="color: #00B7EE;"></i>帖子图片</span>
							</div>
							<el-form-item label="" label-width="0" prop="pics" class="mgtop10">
								<el-upload class="upload-demo" name="mdFile" list-type="picture-card"
									:action="imgUploadUrl" :headers="uploadHeaders" :on-change="picChange"
									:on-remove="picChange" :on-preview="handlePictureCardPreview" :file-list="picList"
									:before-upload="beforePicUpload">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip">只能上传JPG、PNG、JPEG文件，且不超过1M</div>
								</el-upload>
							</el-form-item>
						</div>
						<el-col :span="24">
							<el-form-item label="素材简介" prop="content">
								<el-input v-model="newsForm.content" type="textarea" :rows="6" placeholder="请填写素材简介" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="我的回复" prop="comment">
								<el-input v-model="comment" type="textarea" :rows="6" placeholder="请填写回复内容" />
							</el-form-item>
						</el-col>

					</el-row>

				</el-form>
			</div>

			<div class="wf center">
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="publishComment">立即回复</el-button>
				</span>
			</div>
		</el-dialog>

		<el-dialog :close-on-click-modal="false" :visible.sync="previewVisible" append-to-body>
			<img width="100%" :src="previewImageUrl" alt="">
		</el-dialog>

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	export default {
		name: 'News',
		components: {
			VueUEditor
		},
		data() {
			return {
				comment: "",
				ueditor: null,
				dialogVisible: false,
				previewVisible: false,
				previewImageUrl: "",
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				loading: false,
				tableLocale: {
					emptyText: '暂无数据'
				},
				newsTypeList: [],
				newsClassificationList: [],
				cover: '',
				searchForm: {
					page: 1,
					pageSize: 100
				},
				newsForm: {
					cover: "",
					content: "",
					memberPhone: ""
				},
				superSerial: "",
				picList: [],
				rules: {
					title: [{
							required: true,
							message: '请填写文章标题',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 2 到 100个字符',
							trigger: 'blur'
						}
					],
					classification: [{
						required: true,
						message: '请选择所在分类',
						trigger: 'blur'
					}],
					origin: [{
							required: true,
							message: '请填写素材来源',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					digest: [{
							required: true,
							message: '请填写素材简介',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 200,
							message: '长度在 2 到 200个字符',
							trigger: 'blur'
						}
					],
					subTime: [{
						required: true,
						message: '请选择发布时间',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handlePictureCardPreview(file) {
				this.previewImageUrl = file.url;
				this.previewVisible = true;
			},
			picChange(file, fileList) {
				console.log("文件上传", fileList)
				this.picList = fileList;
			},
			ueditorReady(ueditor) {
				let _this = this
				this.ueditor = ueditor;
				ueditor.addListener("contentChange", () => {
					_this.newsForm.content = ueditor.getContent();
				});
				if (this.newsForm.content != undefined)
					ueditor.setContent(this.newsForm.content)
			},
			menuSelect(item) {
				this.searchForm.superSerial = item;
				this.searchForm.page = 1;
				this.searchForm.name = "";
				this.getClassificationList();
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.cover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.cover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}
			},
			newItem() {
				this.newsForm = {
					cover: "",
					subTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
					classification: ""
				};
				console.log("dsadas", this.ueditor)
				if (this.ueaditor != undefined)
					this.ueditor.setContent("");
				this.dialogVisible = true;
			},
			showItem(newsForm) {
				this.dialogVisible = true;
				this.newsForm = this.$http.cleanData(newsForm);
				newsForm.memberPhone = newsForm.member;
				if (newsForm.classification != null) {
					var claszzes = newsForm.classification.split("-");
					this.superSerial = claszzes[0];
				}

				this.picList = newsForm.pics;
				if (this.newsForm.content != undefined && this.ueditor != null)
					this.ueditor.setContent(this.newsForm.content)
			},
			getNewsTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.newsTypeList, {
					pageSize: 100
				}, false, res => {
					this.newsTypeList = res.data;
					if (this.superSerial == "")
						this.superSerial = this.newsTypeList[0].serial;
					this.getClassificationList();
				});
			},
			getClassificationList() {
				this.newsForm.classification = "";
				this.$http.request(this, this.$apis.material.newsClassificationList, {
					superSerial: this.searchForm.superSerial,
					pageSize: 100
				}, false, res => {
					this.newsClassificationList = res.data;
				});
			},
			publishNews() {
				this.$refs['newsForm'].validate((valid) => {
					if (valid) {
						var newsForm = Object.assign({}, this.newsForm);
						var claszzes = newsForm.classification.split("-");
						newsForm.classification = claszzes[claszzes.length - 1];
						newsForm.id = "";
						newsForm.updateTime = "";
						var picList = [];
						this.picList.filter(file => {
							if (file.status == "success" && file.response.files.length > 0) {
								let doc = file.response.files[0];
								picList.push({
									uid: file.uid,
									name: doc.orianName,
									url: doc.uploadUrl,
									img: doc.uploadUrl
								})
							}
						});
						newsForm.pics = JSON.stringify(picList);
						this.$http.request(this, this.$apis.material.postPublish, newsForm, true, (res) => {
							let data = res.data;
							if (data != null) {
								this.$emit("addNews", data)
							}
							this.dialogVisible = false;
						});
					}
				});
			},
			publishComment() {
				if (this.comment.length == 0) {
					this.$http.showMessage(this, {
						code: 1,
						message: "请先填写回复内容！"
					});

					return;
				}
				this.$http.request(this, this.$apis.material.manageDealComment, {
					reply: this.comment,
					article: this.newsForm.serial,
					type: 1
				}, true, (res) => {

				});
			}
		},
		watch: {
			dialogVisible(newValue, oldValue) {
				if (newValue) {
					this.getNewsTypeCacheList();
				}
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>


<style>
</style>
